import React, { useEffect, useMemo, useState } from 'react';
import { PageTitle } from '././../components/common/PageTitle/PageTitle';
import { References } from '././../components/common/References/References';
import { useResponsive } from '././../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
/* import { TrendingCreators } from '././../components/nft-dashboard/trending-creators/TrendingCreators';
import { RecentlyAddedNft } from '././../components/nft-dashboard/recently-added/RecentlyAddedNft';
import { TrendingCollections } from '././../components/nft-dashboard/trending-collections/TrendingCollections';
import { Balance } from '././../components/nft-dashboard/Balance/Balance';
import { TotalEarning } from '././../components/nft-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from '././../components/nft-dashboard/activityStory/ActivityStory';
import { RecentActivity } from '././../components/nft-dashboard/recentActivity/RecentActivity'; */
import { RecentActivity } from '././../components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './../pages/DashboardPages/DashboardPage.styles';//DashboardPage.styles';
import { BaseRow } from '././../components/common/BaseRow/BaseRow';
import { BaseCol } from '././../components/common/BaseCol/BaseCol';
import { PersonalInfo } from './../components/servicelist/PersonalInfo';
import MediaControlCard from './../components/ServiceProviderList/ServiceCard';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getServiceProviderData } from './../api/serviceprovider.api';
import { notificationController } from './../controllers/notificationController';
import { useAppDispatch, useAppSelector } from './../hooks/reduxHooks';
import { TrendingCollections } from '../components/nft-dashboard/trending-collections/TrendingCollections';
import { EventCollections } from '../components/EventsList/EventCollections';
import { readCommunityCode } from './../services/localStorage.service';
import CommunityList from '../components/CommunityList';
import { BaseModal } from '../components/common/BaseModal/BaseModal';
import { GuestLoginForm } from '../components/auth/GuestLoginForm/GuestLoginForm';
import { LoginForm } from '../components/auth/LoginForm/LoginForm';


const ServiceVendorList: React.FC = () => {
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const { type } = useParams() || ''; 
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const communityCode = readCommunityCode();
  const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
  const location = useLocation();
  const user: any = useAppSelector((state: any) => state.user.user);



/*   useEffect(() => {
   
    if (!communityCode || Object.keys(communityCode).length < 1) {
      setIsMiddleModalOpen(true);
 
    }
  }, [location.pathname]); */


  const handleOk = async (status: any) => {
    const communityCode = readCommunityCode();
    
    if (communityCode && communityCode?.name) {
      setIsMiddleModalOpen(status);
      window.location.href = location.pathname;
      //navigate(location.pathname);
    }else{
      notificationController.error({ message: 'Please Select Your Community' });
    }
    
  }












  const desktopLayout = (
   /*  <BaseRow>


      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow >          
          <BaseCol span={24}>
          <PersonalInfo />
          </BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>

     
    </BaseRow> */




<BaseRow>

{user?.id ? (<S.LeftSideCol xl={16} xxl={17}>
  <BaseRow gutter={[30, 30]}>
    <BaseCol span={24}>
      {type === 'eventtickets' ? <> <EventCollections />  </> : (<MediaControlCard type={type}  />     )}        
    </BaseCol>
  </BaseRow>
 {/*  <References /> */}
</S.LeftSideCol>):(<S.LeftSideCol xl={16} xxl={17}>
  <BaseRow gutter={[30, 30]}>
    <BaseCol span={24}>
    <>  </> 
     

    <GuestLoginForm /> 
    </BaseCol>
  </BaseRow>
 {/*  <References /> */}
</S.LeftSideCol>)


}

{/* <S.LeftSideCol xl={16} xxl={17}>
  <BaseRow gutter={[30, 30]}>
    <BaseCol span={24}>
      {type === 'eventtickets' ? <> <EventCollections />  </> : (<MediaControlCard type={type}  />     )}        
    </BaseCol>
  </BaseRow>
  <References />
</S.LeftSideCol> */}

<S.RightSideCol xl={8} xxl={7}>

  {/* <div id="blood-screening">
    <BloodScreeningCard />
  </div>
  <S.Space />
  <S.ScrollWrapper id="patient-timeline">
    <PatientResultsCard />
  </S.ScrollWrapper> */}
</S.RightSideCol>
</BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      {user?.id ? (<BaseCol span={24}>
      {type === 'eventtickets' ? <> <EventCollections />  </> : (<MediaControlCard type={type}  />     )}
      </BaseCol>):(<BaseCol span={24}>
      {type === 'eventtickets' ? <> <EventCollections />  </> : (<GuestLoginForm />      )}
      </BaseCol>) }





      

      <BaseCol span={24}>
       {/*  <RecentlyAddedNft /> */}
      </BaseCol>

      <BaseCol span={24}>
      {/*   <TrendingCollections /> */}
      </BaseCol>

      <BaseCol span={24}>
        {/* <RecentActivity /> */}
      </BaseCol>
      
    </BaseRow>
  );

  return (
    <>
      <BaseModal
        title={'Please Select Your Community'}
        centered
        open={isMiddleModalOpen}
        onOk={() => handleOk( false)}
        onCancel={() => handleOk( false)}
        size="medium"
        style={{ background: '#FFF' }}

      >
        <CommunityList  />
      </BaseModal>
     <PageTitle>ServiceGate</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ServiceVendorList;
