
import React, { useEffect, useMemo, useState } from 'react';
import { Overlay } from '../../../../common/Overlay/Overlay';
import { useResponsive } from './../../../../../hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, []);


  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={mobileOnly?350:260}
        {...props}
        style={{background: '#2c3e50',flex: 1, zIndex: 2000, position:'relative' }}
      >
         <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />

        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />
        </S.SiderContent> 
      </S.Sider>
      {mobileOnly && <Overlay style={{width:300}}  onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
