import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import { useResponsive } from './../../../../hooks/useResponsive';
import logo from './../../../../assets/logo1.png';
import logoDark from './../../../../assets/logo-dark.png';
import { useAppSelector } from './../../../../hooks/reduxHooks';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import logoicon from './../../../../assets/logoicon.png';
interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly, mobileOnly } = useResponsive();

  const theme = useAppSelector((state:any) => state.theme.theme);

  const img = theme === 'dark' ? logoDark : logo;

  return (
    <S.SiderLogoDiv style={{zIndex:1000}}>
      <S.SiderLogoLink to="/">        
         <S.BrandSpan><p style={{fontSize:'25px'}}><span>{'Menu'}</span></p></S.BrandSpan> 
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
        shape="circle"
        size="small"
        $isCollapsed={isSiderCollapsed}
        icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
        onClick={toggleSider}
      />      
      )}

 {mobileOnly && (
        isSiderCollapsed ? <ArrowForwardIosRoundedIcon 
        
          onClick={toggleSider}
        
        />:<CloseOutlined shape="circle" onClick={toggleSider} />
        
      )} 
    </S.SiderLogoDiv>
  );
};

{/* <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        /> */}